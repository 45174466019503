angular.module('enervexSalesappApp').controller('StickySelectionsCtrl', function($scope, $uibModalInstance, _) {
	$scope.differentValues = _.compact(_.map($scope.stickySelections.props, function(prop, index){
		var propName = $scope.stickySelections.propNames[index]
		var value = $scope.stickySelections.values[prop]
		var existingValue = _.get($scope.design, prop)
		if (value && value != existingValue) {
			return {
				type: prop,
				propName: propName,
				recommended: existingValue,
				value: value
			}
		}
	}))

	$scope.closeModal = function(override) {
		var modalInstance = $uibModalInstance.close({
			override: override,
		});	
	}
});
